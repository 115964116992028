import React from 'react';
import Layout from '../components/Layout';

const Imprint = () => (
  <Layout>
      <p>Impressum</p>
  </Layout>
);

export default Imprint;
